<template>
  <div>
    <section class="s-order">
      <div class="container">
        <h1>Оформление заказа</h1>

        <div class="order-layout display--grid">
          <div class="form-section">
            <form @submit.prevent="send">
              <div class="form-group">
                <label>Фамилия*</label>
                <input
                  v-model="lastName"
                  type="text"
                  class="form-control"
                  error="lastName"
                  required
                />
                <errors field="lastName" :errors="errors"></errors>
              </div>
              <div class="form-group">
                <label>Имя*</label>
                <input
                  v-model="name"
                  type="name"
                  class="form-control"
                  error="name"
                  required
                />
                <errors field="name" :errors="errors"></errors>
              </div>
              <div class="form-group">
                <label>Отчество</label>
                <input
                  v-model="patronymic"
                  type="patronymic"
                  class="form-control"
                  error="patronymic"
                />
                <errors field="patronymic" :errors="errors"></errors>
              </div>
              <div class="form-group">
                <label>Телефон*</label>
                <vue-tel-input
                  class="form-control"
                  v-model="unformatPhone"
                  @on-input="setTelephone"
                  error="telephone"
                ></vue-tel-input>
                <errors field="telephone" :errors="errors"></errors>
              </div>
              <div class="form-group">
                <label>Email*</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  error="email"
                  required
                />
                <errors field="email" :errors="errors"></errors>
              </div>
              <div class="form-group">
                <a
                  href="https://cabinet.way2wei.ru/documents/politic.pdf"
                  target="_blank"
                  >Политика в отношении обработки персональных данных</a
                >
              </div>

              <div class="form-group form-group__coupon">
                <label>Введите промокод</label>
                <input
                  v-model="coupon"
                  type="text"
                  class="form-control"
                  error="coupon"
                  :disabled="disabledCoupon"
                />
                <errors field="coupon" :errors="errors"></errors>

                <button
                  :disabled="disabledCoupon"
                  @click="sendCoupon"
                  type="button"
                  class="btn btn-accent btn-sm"
                >
                  Применить
                </button>
              </div>

              <div class="form-group display--flex form-group__connect">
                <span>Приоритетный способ связи: </span>
                <label
                  for="telephone"
                  :class="{ active: connect_type == 'telephone' }"
                >
                  <span class="label-input">Телефон</span>
                  <input
                    v-model="connect_type"
                    type="radio"
                    value="telephone"
                    id="telephone"
                  />
                </label>
                <label for="email" :class="{ active: connect_type == 'email' }">
                  <span class="label-input">Email</span>
                  <input
                    v-model="connect_type"
                    type="radio"
                    value="email"
                    id="email"
                  />
                </label>
              </div>

              <div class="form-group form-group__pay">
                <span>Способ оплаты: </span>
                <div class="btn-action display--flex">
                  <label
                    for="card"
                    :class="{ active: pay_type == 'card' }"
                    class="btn btn-sm"
                  >
                    <img src="@/assets/images/icons/card-icon.svg" alt="" />
                    <span>Банковская карта</span>
                    <input
                      v-model="pay_type"
                      type="radio"
                      value="card"
                      id="card"
                    />
                  </label>
                  <label
                    for="invoice_payment"
                    :class="{ active: pay_type == 'invoice_payment' }"
                    class="btn btn-sm"
                  >
                    <img src="@/assets/images/icons/invoice-icon.svg" alt="" />
                    <span>Счет на оплату</span>
                    <input
                      v-model="pay_type"
                      type="radio"
                      value="invoice_payment"
                      id="invoice_payment"
                    />
                  </label>
                  <label
                    for="dolyame"
                    :class="{ active: pay_type == 'dolyame' }"
                    class="btn btn-sm"
                  >
                    <img src="@/assets/images/icons/doly-icon.svg" alt="" />
                    <span>Оплата долями</span>
                    <input
                      v-model="pay_type"
                      type="radio"
                      value="dolyame"
                      id="dolyame"
                    />
                  </label>
                </div>

                <private-checkboxes
                    @checkboxchange="(data) => (checkboxes = data)"
                />
              </div>

              <div class="btn-action">
                <button
                  class="btn btn-accent"
                  :disabled="!checkboxes.oferta || !checkboxes.personalData || !getRight('buyProduct') || isDisabledBtn"
                >
                  Отправить
                </button>
              </div>
              <p class="alert" v-if="!getRight('buyProduct')">Покупка доступна только со счета компании. Обратитесь к вашему руководству.</p>
            </form>
          </div>
          <div class="info-section">
            <div class="basket-area">
              <div class="basket-area__title">
                <span class="basket-area__basket">Корзина</span>
                <span class="basket-area__total-price">
                  <span :class="{ coupon: totalPrice }"
                    >{{ generalPrice }} &#8381;</span
                  >
                  <span v-if="totalPrice"> {{ totalPrice }} &#8381;</span>
                </span>
              </div>
              <div class="basket-area__products">
                <div
                  v-for="product in orderedProducts"
                  :key="product.id"
                  class="basket-area__product"
                >
                  <p class="basket-area__product-title">{{ product.name }}</p>
                  <span class="basket-area__product-count"
                    >{{ product.product_count }} x
                  </span>
                  <span
                    :class="{
                      coupon:
                        product.old_price && product.price != product.old_price,
                    }"
                    class="basket-area__product-price coupon"
                    v-if="
                      product.old_price && product.price != product.old_price
                    "
                    >{{ product.old_price }} &#8381;</span
                  >
                  <span class="basket-area__product-price"
                    >{{ product.price }} &#8381;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <pop-up-form @closeModal="closeSuccessModal" v-if="showModalSuccess">
          <div class="popup-container">
            <div class="popup-title">Благодарим за оформление заказа</div>
            <p v-if="pay_type === 'invoice_payment'" class="popup-text">С вами свяжется ваш личный менеджер</p>
            <p class="popup-text">Пожалуйста, проверьте почту</p>
            <div class="btn-action display--flex">
              <router-link to="/" type="button" class="btn btn-o">
                Перейти на главную
              </router-link>
            </div>
          </div>
        </pop-up-form>
        <pop-up-form @closeModal="showFailModal = false" v-if="showFailModal">
          <div class="popup-container">
            <div class="popup-title">Произошла ошибка</div>
            <p class="popup-text">Пожалуйста, сообщите администратору</p>
            <div class="btn-action display--flex">
              <button
                @click="showFailModal = false"
                type="button"
                class="btn btn-o"
              >
                Закрыть
              </button>
            </div>
          </div>
        </pop-up-form>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useErrors from "../../mixins/useErrors";
import { useReCaptcha } from "vue-recaptcha-v3";
import Errors from "../../components/technical/Errors.vue";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import standartError from "../../mixins/standartError";
import axios from "axios";
import PrivateCheckboxes from "../../components/form-elements/private-checkboxes/PrivateCheckboxes.vue";
import useCheckRights from "./../../mixins/useCheckRights";
export default {
  components: { Errors, PopUpForm, PrivateCheckboxes },
  name: "order-page",
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha(),
      { errors, setErrors, clearCustomErrors } = useErrors();
    const router = useRouter(),
      store = useStore(),
      { getRight } = useCheckRights();

    const name = ref(""),
      lastName = ref(""),
      patronymic = ref(""),
      telephone = ref(""),
      email = ref(""),
      connect_type = ref("telephone"),
      pay_type = ref("card"),
      coupon = ref(""),
      unformatPhone = ref(""),
      code = ref(""),
      showModalSuccess = ref(false),
      showFailModal = ref(false),
      isDisabledBtn = ref(false);

    const generalPrice = ref(0),
      totalPrice = ref(null),
      orderedProducts = ref([]),
      isCode = ref(false),
      disabledCoupon = ref(false),
      resend = ref(false);

    const checkboxes = ref({
      oferta: false,
      personalData: false,
      ad: false,
    });

    const user = computed(() => store.state.user.user);

    const setTelephone = (...data) => {
      if (data[1]?.valid) {
        data[2].classList.add("valid-form");
        data[2].parentNode.classList.add("valid-form");
      } else {
        data[2].classList.remove("valid-form");
        data[2].parentNode.classList.remove("valid-form");
      }
      if (data[1]?.number) {
        telephone.value = data[1].number;
      }
    };
    const send = async () => {
      resend.value = false;
      isDisabledBtn.value = true
      let data = {
        name: `${lastName.value} ${name.value} ${patronymic.value}`,
        telephone: telephone.value,
        email: email.value,
        code: code.value,
        advert_accept: checkboxes.value.ad,
      };

      if (!user.value) {
        store
            .dispatch("user/loginByEmail", data)
            .then(() => {
              if (user.value.token) {
                store.dispatch("user/getCurrentProfile").then(() => {
                  if (getRight('buyProducts')) {
                    makeOrder();
                  }
                });
              }
            })
          .catch(standartError(setErrors, clearCustomErrors));
          isDisabledBtn.value = false
      } else {
        makeOrder();
      }
    };
    const makeOrder = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha("order/create");
      let data = {
        lastName: lastName.value,
        name: name.value,
        patronymic: patronymic.value,
        telephone: telephone.value,
        email: email.value,
        connect_type: connect_type.value,
        pay_type: pay_type.value,
        coupon: coupon.value,
        recaptcha_token: token,
        advert_accept: checkboxes.value.ad,
      };
      store
        .dispatch("user/makeOrder", data)
        .then((res) => {
          if (res?.redirectTo) {
            totalPrice.value = null;
            window.location.href = `${res.redirectTo}`;
          } else {
            // eslint-disable-next-line no-undef
            ym(61610965, "reachGoal", "order");
            showModalSuccess.value = true;
            totalPrice.value = null;
            localStorage.removeItem("cartProducts");
            localStorage.removeItem("basketCount");
            localStorage.removeItem("generalPrice");
            localStorage.removeItem("product");
            store.commit("products/setBasketProducts", null);
          }
        })
        .catch((error) => {
          if (error.presponse && error.response.status == 422) {
            setErrors(error.response?.data);
            clearCustomErrors();
            isDisabledBtn.value = false
          } else {
            showFailModal.value = true;
            isDisabledBtn.value = false
          }
        });
    };
    const sendCode = async () => {
      let data = {
        name: `${lastName.value} ${name.value} ${patronymic.value}`,
        telephone: telephone.value,
        email: email.value,
        code: code.value,
      };
      if (resend.value) {
        data.resend = resend.value;
      }
      store
        .dispatch("user/loginByEmail", data)
        .then((res) => {
          if (res?.error && !resend.value) {
            setErrors({ message: [res.message] });
          }
          resend.value = false;
          if (res?.token) {
            store.commit("user/setUserData", res);
            axios.defaults.headers.common = {
              Authorization: `Bearer ${res.token.replace("%7C", "|")}`,
            };
            let userData = JSON.parse(localStorage.getItem('user'))
            if(userData) {
                store
                    .dispatch("user/getCurrentUser")
                    .then(() => {
                        store.dispatch('setYmUid')
                    })
            }
            isCode.value = false;
            makeOrder();
          }
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };
    const sendCoupon = async () => {
      store
        .dispatch("user/couponCheck", coupon.value)
        .then((res) => {
          totalPrice.value = res.total;
          orderedProducts.value = res.products;
          localStorage.setItem(
            "product",
            JSON.stringify(orderedProducts.value)
          );
          disabledCoupon.value = true;
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };
    const closeSuccessModal = () => {
      router.push("/");
    };

    onBeforeMount(async () => {
      store
        .dispatch("user/getCurrentUser")
        .then(() => {
          lastName.value = user.value.last_name;
          name.value = user.value.first_name;
          patronymic.value = user.value.patronymic;
          email.value = user.value.email;
          if (user.value.telephone) unformatPhone.value = user.value.telephone;
        })
        .then(() => {
          store.dispatch('setYmUid')
        })
        .catch(() => store.commit("user/clearUserData"));
      let listProducts = JSON.parse(localStorage.getItem("product"));

      if (listProducts && listProducts.length > 0) {
        let listIds = listProducts.map((product) => product.id);
        await store.dispatch("products/getProductsForBusket", listIds);
        let productsForBusket = store.state.products.productsForBusket,
          calculatePrice = 0;
        productsForBusket.forEach((product) => {
          let oldProduct = listProducts.find((pr) => pr.id == product.id);
          let newProduct = {
            id: product.id,
            price: product.price,
            name: product.name,
            product_count: oldProduct.product_count,
            totalPrice: product.price * oldProduct.product_count,
            isChecked: oldProduct.isChecked,
          };
          calculatePrice += newProduct.totalPrice;
          orderedProducts.value.push(newProduct);
        });
        generalPrice.value = calculatePrice;
        if (generalPrice.value != localStorage.getItem("generalPrice")) {
          localStorage.setItem("generalPrice", generalPrice.value);
        }
      } else {
        router.push("/");
      }
      if (generalPrice.value === "0") {
        pay_type.value = "invoice_payment";
      }
    });

    return {
      name,
      lastName,
      patronymic,
      telephone,
      pay_type,
      email,
      connect_type,
      coupon,
      setTelephone,
      unformatPhone,
      generalPrice,
      totalPrice,
      orderedProducts,
      send,
      resend,
      code,
      isCode,
      errors,
      sendCode,
      sendCoupon,
      makeOrder,
      disabledCoupon,
      showModalSuccess,
      closeSuccessModal,
      showFailModal,
      checkboxes,
      getRight,
      isDisabledBtn
    };
  },
};
</script>

<style lang="scss" scoped>
.s-order {
  padding: 150px 0 60px;

  @media (max-width: 767px) {
    padding: 100px 0 60px;
  }
}

.order-layout {
  display: flex;
  width: 100%;

  .form-section {
    flex: 1 1 60%;
    padding: 20px 10px 10px 0px;
  }

  .info-section {
    flex: 1 1 40%;
    padding: 20px 0px 10px 20px;
  }

  @media (max-width: 915px) {
    flex-direction: column-reverse;

    .form-section {
      flex: 1 1 100%;
      padding: 0;
    }

    .info-section {
      flex: 1 1 100%;
      padding: 0;
      margin-bottom: 20px;
    }
  }
}

.basket-area {
  background-color: var(--color-light);
  padding: 20px;

  &__title {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    flex-wrap: wrap;
  }

  // .basket-area__basket

  &__basket {
    font-weight: 500;
    font-size: 22px;
  }

  // .basket-area__total-price

  &__total-price .coupon {
    text-decoration: line-through;
    margin-right: 8px;
    color: #6d718b;
  }

  // .basket-area__product

  &__product {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
  }

  // .basket-area__product-title

  &__product-title {
    margin-bottom: 5px;
  }

  // .basket-area__product-price

  &__product-price.coupon {
    text-decoration: line-through;
    margin-right: 8px;
    color: #6d718b;
  }
}

form {
  padding: 35px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

form .form-group + .form-group {
  margin-top: 20px;
}

form .form-group + .form-action {
  margin-top: 35px;
}

.form-group {
  &__coupon {
    margin: 30px 0 !important;

    label {
      font-weight: 500;
    }

    button {
      margin-top: 15px;
    }
  }

  &__connect {
    flex-wrap: wrap;

    label {
      position: relative;
      display: block;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin: 0 70px 0 20px;
      border: 1px solid var(--border-color);
      border-radius: 50%;
    }

    .label-input {
      padding-left: 30px;
    }

    input {
      width: 0;
      height: 0;
    }

    .active {
      background: var(--accent);
      border-color: var(--accent);
    }

    .active::before {
      display: block !important;
    }

    label::before {
      position: absolute;
      display: none;
      content: "";
      top: 5px;
      left: 4px;
      width: 13px;
      height: 8px;
      border: none;
      border-bottom: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      transform: rotate(-45deg);
    }

    @media (max-width: 500px) {
      label {
        margin-top: 10px;
        margin-left: 0;
        margin-right: 95px;
      }
    }
  }

  &__pay {
    margin-bottom: 20px;
    input {
      width: 0;
      height: 0;
    }
    img {
      margin-right: 10px;
    }

    .btn {
      font-size: 15px;
    }

    .btn-action {
      flex-wrap: nowrap;
      align-items: center;
    }

    label {
      height: 38px;
    }

    label.active {
      color: #ffffff;
      background-color: var(--accent);
      border-color: var(--accent);
    }

    .btn-action {
      margin-top: 10px;
    }
  }
}
.popup-container {
  margin-right: 35px;

  .popup-title {
    margin-bottom: 30px;
    font-size: 39px;
    line-height: 51px;
    padding-right: 5px;

    @media (max-width: 767px) {
      .popup-title {
        font-size: 36px;
        line-height: 43px;
      }
    }
  }

  .popup-text {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    margin: 5px 5px 10px 0;
    display: inline-block;
  }

  .btn-l-accent {
    padding: 2px 0 0 0;
    margin: 9px 0 10px 0;
  }

  .btn-l-accent:hover {
    border-color: transparent;
  }
}
.checkboxes {
  font-size: 1.05rem;
  word-spacing: 0.9%;
  margin: 15px 0 9px 0;

  label {
    padding-left: 26px;
    position: relative;
  }

  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-right: 10px;
  }

  input {
    height: 0;
    width: 0;
  }

  input:checked ~ label::after {
    content: "";
    background-image: url("./../../assets/images/icons/check.svg");
    background-size: cover;
    position: absolute;
    top: 3px;
    left: 0.5px;
    height: 19px;
    width: 19px;
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
}
</style>
